/* .HumidityCard {
} */

.HumidityCard > .Card {
  width: 100%;
  height: 100%;
}

.HumidityCard > .Card .GaugeChart {
  width: 18rem;
}
