.ChartStatisticsCard {
  border: 1px solid var(--color-surface);
  border-radius: 1rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ChartStatisticsCard > .title {
  font-size: var(--font-size-3);
}

.ChartStatisticsCard > .body > .Stat {
  font-size: var(--font-size-3);

  display: flex;
}

.ChartStatisticsCard > .body > .Stat > .label {
  width: 7rem;
}
