.Authenticated {
  width: 100vw;
  height: 100vh;

  overflow: auto;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.Authenticated > .Sidebar {
  position: sticky;
  top: 0;
}

.Authenticated > .body {
  width: 100%;
  max-width: 58rem;
  height: fit-content;
  min-height: 100%;

  display: flex;
}

.Authenticated > * {
  flex-shrink: 0;
}

.Authenticated > .body > * {
  flex: 1 1 0;
  padding: 2rem 0 2rem calc(var(--card-gap) / 2);
}

@media screen and (max-width: 1200px) {
  .Authenticated > .body {
    padding-bottom: var(--mobile-navbar-height);
  }

  .Authenticated > .body > * {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 950px) {
  .Authenticated > .body > * {
    padding: 2rem;
  }
}
