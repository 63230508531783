.Card {
  background: var(--color-surface);
  border-radius: 1rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
}

.Card > .title {
  font-size: var(--font-size-3);
}

.Card > .body {
  flex: 1 1 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
