.App {
  flex: 1 1 0;

  display: flex;
}

@keyframes fade-in-App {
  from {
    opacity: 0;
  }
}

.App > * {
  animation: fade-in-App 0.2s;
}
