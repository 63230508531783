/* .RainfallCard {
} */

.RainfallCard > .Card {
  width: 100%;
  height: 100%;
}

.RainfallCard > .Card > .body {
  align-items: stretch;
}

.RainfallCard > .Card > .body > .left,
.RainfallCard > .Card > .body > .right {
  flex: 1 1 0;
}

.RainfallCard > .Card > .body > .left {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr auto;
  column-gap: 0.5rem;
}

.RainfallCard > .Card > .body > .left > .Bar {
  --width: 1.5rem;

  width: var(--width);
  margin: 0.5rem 0rem;
  background: var(--color-surface-dark);
  border-radius: 1rem;
  justify-self: center;

  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.RainfallCard > .Card > .body > .left > .Bar > .inner {
  --bar-height: 50%;

  width: 100%;
  height: calc(var(--width) + var(--bar-height));
  margin-bottom: calc(-1 * var(--width));
  background: var(--color-secondary);
  border-radius: 1rem;

  transition: height 0.8s ease-out, background 0.8s ease-out;
}

.RainfallCard > .Card > .body > .left > .Bar > .inner.level-1 {
  background: var(--color-yellow);
}
.RainfallCard > .Card > .body > .left > .Bar > .inner.level-2 {
  background: var(--color-orange);
}
.RainfallCard > .Card > .body > .left > .Bar > .inner.level-3 {
  background: var(--color-red);
}

.RainfallCard > .Card > .body > .left > .CurrentValue {
  justify-self: center;
  align-self: center;
}

.RainfallCard > .Card > .body > .left > .CurrentValue > .value {
  font-size: var(--font-size-1);
}

.RainfallCard > .Card > .body > .left > .CurrentValue > .unit {
  font-size: var(--font-size-3);
  text-align: center;
}

.RainfallCard > .Card > .body > .left > svg {
  width: 2rem;
  height: 2rem;
  justify-self: right;
}

.RainfallCard > .Card > .body > .left > .State {
  width: 6.5rem; /* Longest state string */
  font-size: var(--font-size-3);
  text-align: center;
  align-self: center;
}

.RainfallCard > .Card > .body > .divider {
  width: 1px;
  background: var(--color-primary);
}

.RainfallCard > .Card > .body > .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.RainfallCard > .Card > .body > .right > .Stat {
  text-align: center;
}

.RainfallCard > .Card > .body > .right > .Stat > .label {
  font-size: var(--font-size-3);
}

.RainfallCard > .Card > .body > .right > .Stat > .value-container > .value {
  font-size: var(--font-size-2);
}

.RainfallCard > .Card > .body > .right > .Stat > .value-container > .unit {
  font-size: var(--font-size-3);
}
