.LastUpdatedTimeHeaderCard {
  padding: 1rem;
  background: var(--color-surface);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LastUpdatedTimeHeaderCard > .title {
  font-size: var(--font-size-3);
}

.LastUpdatedTimeHeaderCard > .time {
  font-size: var(--font-size-2);
}

@media screen and (max-width: 1050px) {
  .LastUpdatedTimeHeaderCard {
    width: var(--mobile-card-width);
  }
}
