/* .Sidebar {
} */

@keyframes fade-in-Sidebar {
  from {
    opacity: 0;
  }
}
.Sidebar > * {
  animation: fade-in-Sidebar 0.2s;
}

.Sidebar > .desktop {
  width: 16rem;
  height: 100%;
  padding: 2rem calc(var(--card-gap) / 2) 2rem 0;
  font-size: var(--font-size-3);

  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.Sidebar > .desktop > img#mdt-logo {
  align-self: center;
  width: 10rem;
  margin-bottom: 2rem;
}

.Sidebar > .desktop > .divider {
  flex-shrink: 0;
  height: 1px;
  background: var(--color-primary);
  margin: 1rem;
}

.Sidebar > .desktop .list-container {
  display: flex;
  flex-direction: column;
}

.Sidebar > .desktop .list-container > .list-element {
  height: 3rem;
  padding-left: 1rem;
  border-radius: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Sidebar > .desktop .list-container > button.list-element {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: inherit;
}

.Sidebar > .desktop .list-container > a.list-element {
  text-decoration: none;
  padding-right: 1rem;
}

.Sidebar > .desktop .list-container > .list-element.selected {
  background: var(--color-primary);
}

.Sidebar > .desktop .list-container > button.list-element:not(.selected):hover,
.Sidebar > .desktop .list-container > a.list-element:not(.selected):hover {
  background: var(--color-primary-translucent);
}

.Sidebar > .desktop .ExternalLink > svg,
.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown > .title > svg {
  width: 2rem;
  height: 2rem;
  fill: var(--color-primary);
}

.Sidebar > .desktop .ExternalLink > svg {
  transform: scale(0.75);
}

.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown {
  overflow: hidden;
}

.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown > .title {
  overflow: hidden;
  padding-right: 1rem;
}

.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown > .title > svg {
  transform: scale(1.5);
  transition: transform 0.2s;
}

.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown.expanded > .title > svg {
  transform: scale(1.5) rotate(180deg);
}
.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown > .body {
  /* Fixed height for CSS transition. Modified from JS. */
  --num-cctvs: 0;

  height: calc(3rem * var(--num-cctvs));
  transition: height 0.2s;
}

.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown:not(.expanded) > .body {
  height: 0;
}

.Sidebar > .desktop > .ExternalLinks > .CctvsDropdown > .body > * {
  flex-shrink: 0;
  padding-left: 2rem;
}

.Sidebar .OnlineIndicator {
  width: 1rem;
  height: 1rem;
  border-radius: 100rem;
  background: var(--color-green);
}
.Sidebar .OnlineIndicator.offline {
  background: var(--color-red);
}

.Sidebar > .desktop > .DataSourcesContainer > .title {
  font-weight: bold;
}

.Sidebar > .desktop > .DataSourcesContainer > *:not(:first-child) {
  padding-left: 2rem;
}

.Sidebar > .desktop > .DataSourcesContainer > .DataSource {
  padding: 0 2rem;
}

.Sidebar > .MobileBottomBar {
  display: none;
}

@media screen and (max-width: 1200px) {
  .Sidebar {
    z-index: 99;
  }

  .Sidebar.expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-bottom: var(--mobile-navbar-height);
  }

  .Sidebar:not(.expanded) > .desktop {
    display: none;
  }

  .Sidebar.expanded > .desktop {
    flex: 1 1 0;
    width: 100%;
    background: var(--color-surface-dark);
    padding: 2rem;

    overflow: auto;
  }

  .Sidebar > .MobileBottomBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: var(--mobile-navbar-height);
    padding: 0 2rem;
    background: var(--color-surface);
    border: none;
    font-size: var(--font-size-3);

    display: flex;
    align-items: center;
    gap: 2rem;
  }

  /* .Sidebar.expanded > .MobileBottomBar {
  } */

  .Sidebar > .MobileBottomBar > .divider {
    align-self: stretch;
    width: 1px;
    margin: 0.5rem 0;
    background: var(--color-primary);
  }

  .Sidebar > .MobileBottomBar > .DataSource {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .Sidebar > .MobileBottomBar > svg {
    width: 2rem;
    height: 2rem;
    margin-left: auto;
  }
}
