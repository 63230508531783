.Login {
  flex: 1 1 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Login > .login-card {
  width: 25rem;
  height: 32rem;
  padding: 2rem;
  background: var(--color-surface);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login > .login-card > img.mdt-logo {
  width: 12rem;
  margin-bottom: 2rem;
}

.Login > .login-card > input.username,
.Login > .login-card > input.password {
  width: 21rem;
  height: 4rem;
  padding: 1rem;
  border: none;
  background: var(--color-surface-dark);
  border-radius: 1rem;
  font-size: var(--font-size-3);
  outline: none;
}

.Login > .login-card > input.password {
  margin-top: 1rem;
}

.Login > .login-card > .error-message {
  align-self: flex-end;
  color: var(--color-red);
}

.Login > .login-card > button.log-in {
  width: 21rem;
  height: 4rem;
  padding: 1rem;
  margin-top: auto;
  border: none;
  background: var(--color-primary);
  border-radius: 1rem;
  font-size: var(--font-size-3);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Login > .login-card > button.log-in:not(:disabled):hover {
  filter: brightness(1.1);
}
