.ChartSkeleton {
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
}

.ChartSkeleton > *,
.ChartSkeleton > * > * {
  flex-shrink: 0;
}

.ChartSkeleton > .header {
  display: flex;
  flex-wrap: wrap;
  gap: var(--card-gap);
}

.ChartSkeleton > .header > * {
  flex: 1 1 0;
  max-width: 33.5rem; /* time select card's max width */
  height: 7rem;
}

.ChartSkeleton > .body {
  flex: 1 1 0;

  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
}

.ChartSkeleton > .body > .bottom {
  display: flex;
  gap: var(--card-gap);
  flex-wrap: wrap;
}

.ChartSkeleton > .body > .bottom > .ChartStatisticsCard {
  flex: 1 1 0;
}

@media screen and (max-width: 600px), screen and (max-height: 900px) {
  .ChartSkeleton > .body {
    min-height: max(90vh, 30rem);
  }
}

@media screen and (max-width: 600px) {
  .ChartSkeleton > .header > * {
    max-width: 21rem; /* time select card's max width */
  }

  .ChartSkeleton > .header > .HistoricTimeSelectHeaderCard {
    height: auto;
    flex-direction: column;
  }

  .ChartSkeleton > .body > .HistoricChartCard {
    overflow-x: scroll;
  }

  .ChartSkeleton > .body > .HistoricChartCard > .Overlay > .children-container {
    min-width: 210%;
  }

  .ChartSkeleton > .body > .bottom {
    flex-direction: column;
  }
}
