.StraightGauge {
  --value: 50%;

  display: flex;
  flex-direction: column;
}
.StraightGauge.vertical {
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}

.StraightGauge > .bar {
  width: 100%;
  height: 3rem;
  background: var(--color-surface-dark);
  border-radius: 1rem;
  overflow: hidden;
}
.StraightGauge.vertical > .bar {
  width: 3rem;
  height: 12rem;

  position: relative;
}

.StraightGauge > .bar > .inner {
  width: var(--value);
  height: 100%;
  background: var(--color-secondary);

  transition: width 0.8s ease-out;
}
.StraightGauge.vertical > .bar > .inner {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--value);

  transition: height 0.8s ease-out;
}

.StraightGauge > .labels-container {
  font-size: var(--font-size-3);

  display: flex;
  justify-content: center;
}
.StraightGauge.vertical > .labels-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.StraightGauge > .labels-container > div:not(.value-container) {
  flex: 1 1 0;
}
.StraightGauge.vertical > .labels-container > div:not(.value-container) {
  flex: 0 0 0;
}

.StraightGauge > .labels-container > .value-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.StraightGauge.vertical > .labels-container > .value-container {
  flex-direction: row;
  align-items: flex-end;
  gap: 0.5ch;
}

.StraightGauge > .labels-container > .value-container > .value {
  font-size: var(--font-size-1);
}

.StraightGauge > .labels-container > .value-container > .unit {
  white-space: nowrap;
}
.StraightGauge.vertical > .labels-container > .value-container > .unit {
  margin-bottom: 0.1rem;
}

.StraightGauge > .labels-container > .max {
  display: flex;
  justify-content: flex-end;
}
