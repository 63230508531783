:root {
  /* Font sizes */
  --font-size-1: 2rem;
  --font-size-2: 1.5rem;
  --font-size-3: 1.25rem;
  /* Main colors */
  --color-darkest-blue: hsl(200, 10%, 10%, 1);
  --color-darker-blue: hsl(200, 20%, 20%, 1);
  --color-darker-blue-1: hsl(200, 15%, 15%, 1);
  --color-dark-blue: hsl(200, 30%, 30%, 1);
  --color-dark-blue-translucent: hsl(200, 30%, 30%, 0.5);
  --color-blue: hsl(200, 45%, 45%, 1);
  --color-green: hsl(150, 45%, 45%, 1);
  --color-yellow: hsl(50, 45%, 45%, 1);
  --color-orange: hsl(25, 45%, 45%, 1);
  --color-red: hsl(5, 45%, 45%, 1);
  /* Color roles */
  --color-font: hsl(0, 0%, 100%, 1);
  --color-background: var(--color-darkest-blue);
  --color-surface: var(--color-darker-blue);
  --color-surface-dark: var(--color-darker-blue-1);
  --color-primary: var(--color-dark-blue);
  --color-primary-translucent: var(--color-dark-blue-translucent);
  --color-secondary: var(--color-blue);
  --color-thi-level-1: var(--color-green);
  --color-thi-level-2: var(--color-yellow);
  --color-thi-level-3: var(--color-orange);
  --color-thi-level-4: var(--color-red);
  /* Component colors */
  --color-scrollbar-track: transparent;
  --color-scrollbar-thumb: var(--color-primary);
  /* Misc */
  --mobile-navbar-height: 3rem;
  /* Card size */
  --card-gap: 1rem;
  --mobile-card-width: 26rem;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf');
  font-weight: bold;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  color: var(--color-font);
  user-select: none;
}

/* Srollbar (start) */
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
::-webkit-scrollbar-track {
  margin: 1rem 0;
  background-color: var(--color-scrollbar-track);
  border-radius: 10rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar-thumb);
  border-radius: 10rem;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
/* Srollbar (end) */

body {
  margin: 0;
  background: var(--color-background);
}

#root {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
}

@media screen and (max-width: 448px) {
  :root {
    font-size: 12px;
  }
}
