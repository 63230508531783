.HistoricChartCard {
  --color-grid: var(--color-surface-dark);
  --color-line: var(--color-secondary);

  flex: 1 1 0;
  padding: 1rem;
  border: 1px solid var(--color-surface);
  border-radius: 1rem;
}

.HistoricChartCard > .Overlay,
.HistoricChartCard > .Overlay > * {
  width: 100%;
  height: 100%;
}

.HistoricChartCard > .Overlay {
  position: relative;
}

.HistoricChartCard > .Overlay > .state-element-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HistoricChartCard > .Overlay > .children-container.translucent {
  opacity: 0.1;
}

.HistoricChartCard > .Overlay > .state-element-container > .message {
  font-size: var(--font-size-3);

  display: flex;
  align-items: center;
  gap: 1ch;
}

.HistoricChartCard > .Overlay > .state-element-container > .message > svg {
  width: 3rem;
  height: 3rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.HistoricChartCard > .Overlay > .state-element-container > .spinner {
  width: 4rem;
  height: 4rem;
  border: 2px solid white;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

.HistoricChartCard > .Overlay > .children-container > .ChartJsResponsive {
  width: 100%;
  height: 100%;
}
