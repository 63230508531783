.WindDirectionCard {
  --color-compass-pointer: var(--color-secondary);
}

.WindDirectionCard > .Card {
  width: 100%;
  height: 100%;
}

.WindDirectionCard > .Card > .body > .compass {
  --compass-angle: 0deg;

  width: 10rem;
  margin: 1rem;
  aspect-ratio: 1;

  position: relative;
}

.WindDirectionCard > .Card > .body > .compass > .circle {
  aspect-ratio: 1;
  border-radius: 50%;
  background: conic-gradient(
      from -5deg,
      transparent 0deg,
      var(--color-compass-pointer) 1deg 9deg,
      transparent 10deg
    ),
    var(--color-surface-dark);
  transform: rotate(var(--compass-angle));

  transition: transform 0.8s ease-out;
}

.WindDirectionCard > .Card > .body > .compass > .direction {
  position: absolute;
  transform: translate(-50%, -50%);
}
.WindDirectionCard > .Card > .body > .compass > .direction#N {
  top: 1rem;
  left: 50%;
}
.WindDirectionCard > .Card > .body > .compass > .direction#E {
  top: 50%;
  left: calc(100% - 1rem);
}
.WindDirectionCard > .Card > .body > .compass > .direction#S {
  top: calc(100% - 1rem);
  left: 50%;
}
.WindDirectionCard > .Card > .body > .compass > .direction#W {
  top: 50%;
  left: 1rem;
}
.WindDirectionCard > .Card > .body > .compass > .direction#NE {
  top: calc(25% - 0%);
  left: calc(75% + 0%);
}
.WindDirectionCard > .Card > .body > .compass > .direction#SE {
  top: calc(75% + 0%);
  left: calc(75% + 0%);
}
.WindDirectionCard > .Card > .body > .compass > .direction#SW {
  top: calc(75% + 0%);
  left: calc(25% - 0%);
}
.WindDirectionCard > .Card > .body > .compass > .direction#NW {
  top: calc(25% - 0%);
  left: calc(25% - 0%);
}
