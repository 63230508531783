.ThiHeaderCard {
  padding: 1rem;
  background: var(--color-surface);
  border-radius: 1rem;

  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.ThiHeaderCard.level-1 {
  background: var(--color-thi-level-1);
}
.ThiHeaderCard.level-2 {
  background: var(--color-thi-level-2);
}
.ThiHeaderCard.level-3 {
  background: var(--color-thi-level-3);
}
.ThiHeaderCard.level-4 {
  background: var(--color-thi-level-4);
}

.ThiHeaderCard > .Tooltip {
  z-index: 1;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 1000rem;
  border: 1px solid var(--color-font);

  display: flex;
  justify-content: center;
  align-items: center;
}

.ThiHeaderCard > .Tooltip > .message {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: var(--color-surface);
  border-radius: 0.5rem;
  padding: 0.5rem;
  white-space: pre;
}

.ThiHeaderCard > .Tooltip:not(:hover) > .message {
  display: none;
}

.ThiHeaderCard > .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ThiHeaderCard > .left > .title {
  font-size: var(--font-size-3);
}

.ThiHeaderCard > .left > .Value > .value {
  font-size: var(--font-size-2);
}

.ThiHeaderCard > .left > .Value > .stress-category {
  font-size: var(--font-size-3);
}

.ThiHeaderCard > .PctChange {
  margin-bottom: 0.1rem;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.ThiHeaderCard > .PctChange > svg {
  width: 2rem;
  height: 2rem;
  transform: scale(1.1);
}

.ThiHeaderCard > .PctChange > svg.triangle {
  transform: scale(2);
}

.ThiHeaderCard > .PctChange > svg.triangle-up {
  transform: scale(2) rotate(180deg);
}

.ThiHeaderCard > .PctChange > .value {
  font-size: var(--font-size-3);
}

@media screen and (max-width: 1050px) {
  .ThiHeaderCard > .Tooltip {
    width: 1.5rem;
    height: 1.5rem;
    font-size: var(--font-size-3);
  }

  .ThiHeaderCard > .Tooltip > .message {
    left: unset;
    right: 1rem;
    font-size: 1rem;
  }
}
