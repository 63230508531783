.CsvDownloadCard {
  border: 1px solid var(--color-surface);
  border-radius: 1rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
}

.CsvDownloadCard > .title {
  font-size: var(--font-size-3);
}

.CsvDownloadCard > .body {
  flex: 1 1 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.CsvDownloadCard > .body > button {
  width: 4rem;
  height: 4rem;
  padding: 0;
  background: none;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}
.CsvDownloadCard > .body > button:disabled {
  opacity: 0.25;
  cursor: default;
}
.CsvDownloadCard > .body > button:not(:disabled):hover {
  background: var(--color-primary-translucent);
}
