.HistoricTimeSelectHeaderCard {
  min-width: max-content;
  padding: 1rem;
  background: var(--color-surface);
  border-radius: 1rem;

  display: flex;
  gap: 1rem;
}

.HistoricTimeSelectHeaderCard > .StartTime,
.HistoricTimeSelectHeaderCard > .right > .Duration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.HistoricTimeSelectHeaderCard > .StartTime {
  width: 16rem;
}

.HistoricTimeSelectHeaderCard > .StartTime > .top {
  display: flex;
  justify-content: space-between;
}

.HistoricTimeSelectHeaderCard > .StartTime > .top > .label,
.HistoricTimeSelectHeaderCard > .right > .Duration > .label {
  font-size: var(--font-size-3);
}

.HistoricTimeSelectHeaderCard > .StartTime > .top > .usage-message {
  align-self: flex-end;
  color: hsl(0, 0%, 50%, 1);
}

.HistoricTimeSelectHeaderCard > .StartTime > input,
.HistoricTimeSelectHeaderCard > .right > .Duration > select {
  height: 3rem;
  width: 100%;
  background: var(--color-surface-dark);
  border: none;
  border-radius: 1rem;
  font-size: var(--font-size-3);
  padding: 0 0.5rem;
  outline: none;
}

.HistoricTimeSelectHeaderCard
  > .StartTime
  > input::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.HistoricTimeSelectHeaderCard > .right {
  display: flex;
  gap: inherit;
}

.HistoricTimeSelectHeaderCard > .right > .Duration > select {
  cursor: pointer;
}

.HistoricTimeSelectHeaderCard > .right > .Duration > select:disabled {
  cursor: default;
}

.HistoricTimeSelectHeaderCard > .right > .UpdateButton {
  align-self: center;
  width: 4rem;
  height: 4rem;
  margin-left: auto;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}

.HistoricTimeSelectHeaderCard > .right > .UpdateButton:disabled {
  opacity: 0.5;
  cursor: default;
}

.HistoricTimeSelectHeaderCard > .right > .UpdateButton:not(:disabled):hover {
  background: var(--color-primary-translucent);
}

.HistoricTimeSelectHeaderCard > .right > .UpdateButton:not(:disabled):active {
  background: var(--color-primary);
}

.HistoricTimeSelectHeaderCard > .right > .UpdateButton > svg {
  width: 3rem;
  height: 3rem;
}
