.HomeSoil > .HomeSkeleton > .body > * > .Card {
  width: 100%;
  height: 100%;
}

.HomeSoil
  > .HomeSkeleton
  > .body
  > .StraightGaugeCard
  > .Card
  > .body
  > .StraightGauge {
  width: 18rem;
}
