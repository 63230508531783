/* .BaroPressCard {
} */

.BaroPressCard > .Card {
  width: 100%;
  height: 100%;
}

.BaroPressCard > .Card > .body > .StraightGauge {
  width: 18rem;
}
