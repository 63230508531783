.HomeSkeleton {
  flex: 1 1 0;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
}

.HomeSkeleton > .header {
  display: flex;
  flex-wrap: wrap;
  gap: var(--card-gap);
}

.HomeSkeleton > .header > * {
  flex: 1 0 0;
  height: 6rem;
}

.HomeSkeleton > .body {
  display: grid;
  gap: var(--card-gap);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 16rem;
}

@media screen and (max-width: 950px) {
  .HomeSkeleton > .body {
    grid-template-columns: 1fr;
  }
}
