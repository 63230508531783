.DataTypeSelectHeaderCard {
  min-width: max-content;
  padding: 1rem;
  background: var(--color-surface);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DataTypeSelectHeaderCard > .label {
  font-size: var(--font-size-3);
}

.DataTypeSelectHeaderCard > select {
  height: 3rem;
  width: 100%;
  background: var(--color-surface-dark);
  border: none;
  border-radius: 1rem;
  font-size: var(--font-size-3);
  padding: 0 0.5rem;
  outline: none;
  cursor: pointer;
}
