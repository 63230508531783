.BatteryStatusHeaderCard {
  padding: 1rem;
  background: var(--color-surface);
  border-radius: 1rem;

  display: flex;
  justify-content: space-between;
}

.BatteryStatusHeaderCard.low {
  background: var(--color-red);
}

.BatteryStatusHeaderCard.normal {
  background: var(--color-green);
}

.BatteryStatusHeaderCard > .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BatteryStatusHeaderCard > .left > .title {
  font-size: var(--font-size-3);
}

.BatteryStatusHeaderCard > .left > .status {
  font-size: var(--font-size-2);
}

.BatteryStatusHeaderCard > svg {
  align-self: center;
  width: 2rem;
  height: 2rem;
}
