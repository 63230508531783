.GaugeChart {
  --color-gauge-background: var(--color-surface-dark);
  --color-gauge-primary: var(--color-secondary);

  display: flex;
  flex-direction: column;
}

.GaugeChart > .labels-container {
  margin: 0 0.625rem;
  font-size: var(--font-size-3);

  position: relative;
  display: flex;
  justify-content: space-between;
}

.GaugeChart > .labels-container > .min,
.GaugeChart > .labels-container > .max {
  width: 2.5rem;
  text-align: center;
}

.GaugeChart > .labels-container > .value {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);

  font-size: var(--font-size-1);
}
