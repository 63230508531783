.TemperatureCard {
  --color-grid: var(--color-surface-dark);
  --color-outside: var(--color-blue);
  --color-dew-point: var(--color-green);
  --color-heat-index: var(--color-blue);
  --color-wind-chill: var(--color-green);
  /* Font sizes below used by Chart.JS must be in rem */
  --font-size-scales: 1rem;
  --font-size-overlay-values: var(--font-size-1);
}

.TemperatureCard > .Card {
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
}

.TemperatureCard > .Card > .body > .ChartJsResponsive {
  width: 100%;
  height: 100%;
}
