/* .WindSpeedCard {
} */

.WindSpeedCard > .Card {
  width: 100%;
  height: 100%;
}

.WindSpeedCard > .Card > .body > .GaugeChart {
  width: 18rem;
}
