/* Set min widths to prevent flash on initial weather data load */
.HomeWeather > .HomeSkeleton > .header > .ThiHeaderCard {
  min-width: 21.5rem;
}
.HomeWeather > .HomeSkeleton > .header > .BatteryStatusHeaderCard {
  min-width: 11.7rem;
}
.HomeWeather > .HomeSkeleton > .header > .LastUpdatedTimeHeaderCard {
  min-width: 14.1rem;
}

.HomeWeather > .HomeSkeleton > .body > .TemperatureCard {
  grid-column: span 2;
}

.HomeWeather
  > .HomeSkeleton
  > .body
  > .TemperatureCard
  > .Card
  > .body
  > .ChartJsResponsive {
  max-width: 40rem;
}

@media screen and (max-width: 950px) {
  .HomeWeather > .HomeSkeleton > .body > .TemperatureCard {
    grid-column: span 1;
  }
}
